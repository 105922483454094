import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Phaser from 'phaser';
import { ProfileDetail, VillagesList } from '@app/_models';
import { MapInfoList } from '@app/_models/maps/mapInfoList';
import { MapService } from '@app/_services/map.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ShowFieldModalComponent } from '@app/_modal/show-field-modal.component';
import { ModalSendTroopsComponent } from '@app/_modal/modal-send-troops.component';
import { ModalSendWorkersComponent } from '@app/_modal/modal-send-workers.component';
import { ModalBuildComponent } from '@app/_modal/modal-build.component';
import { ModalFieldDetailsComponent } from '@app/_modal/modal-field-details.component';
import { ProfileService } from '@app/_services';
import { ShowFieldModalSendTroopsComponent } from '@app/_modal/show-field-modal-send-troops.component';
import { ShowFieldModalBuildComponent } from '@app/_modal/show-field-modal-build.component';
import { ShowFieldModalSendWorkersComponent } from '@app/_modal/show-field-modal-send-workers.component';


@Component({ templateUrl: 'maps.component.html',
styleUrls: ['./maps.component.css'],
styles: ['.custom-class-modal { padding: 20px; background: #fff; margin: 40px; width: 50px; height: 50px; position: absolute; top: 250px; right: 200px; bottom: 0;left: 500;  }'] })
export class MapsComponent implements OnInit {

    //eventsCenter: Phaser.Events.EventEmitter;
    coordx: string = "0";
    coordy: string = "0";
    villagesList : VillagesList;
    mapInfoList : MapInfoList;
    modalRef: NgbModalRef;
    modalRefSendTroops: NgbModalRef;
    modalRefBuild: NgbModalRef;
    modalRefSendWorkers: NgbModalRef;
    modalRefFieldDetails: NgbModalRef;
    modalRefSendMessage: NgbModalRef;
    modalRefSendSources: NgbModalRef;
    modalRefVillageDetails: NgbModalRef;
    modalRefInfoSendTroops: NgbModalRef;
    modalRefInfoSendWorkers: NgbModalRef;
    actualDetailFieldX: number = 0;
    actualDetailFieldY: number = 0;
    highlightMap: boolean = false;
    profileDetail: ProfileDetail;
    highlightMapZoom: boolean = false;
    isZoomMapValue: boolean = false;


     constructor(private mapService: MapService,
        private router: Router,
        private modalService: NgbModal,
        private modalInfoSendTroops: NgbModal,
        private modalInfoSendWorkers: NgbModal,
        private modalInfoBuild: NgbModal,
        private modalSendTroops: NgbModal,
        private modalSendWorkers: NgbModal,
        private modalSendBuild: NgbModal,
        private modalFieldDetails: NgbModal,
        private modalSendSources: NgbModal,
        private modalSendMessage: NgbModal,
        private modalVillageDetails: NgbModal,
        private profileService: ProfileService)
      {
        //this.mapService.getUserVillages().subscribe(result => this.villagesList = result);
        this.mapService.getMapInfoList().subscribe(result => {
          this.mapInfoList = result;
          mapInfoListShared = result;
          if (isZoomMap == false && result?.tutorialDetail.inProgress == false && result?.tutorialDetail.type == "MapZoom" && result?.tutorialDetail.name == "SendWorkers")
          {
              this.highlightMapZoom = true;
          } else
          {
              this.highlightMapZoom = false;
          }
          xShared = result?.villageList?.selectedVillage.x.toString();
          yShared = result?.villageList?.selectedVillage.y.toString();
          //this.loadMap();
          setTimeout(() => this.loadMap(),600);
        });
        isZoomMap = false;

      //setTimeout(() => this.loadVillages(1),400);
      //setTimeout(() => this.loadMapInfo(1),400);

       mapConfig = {
        type: Phaser.AUTO,
        height: 600,
        width: 600,
        scene: [ MainScene, DetailScene ],
        parent: 'mapContainer',
        physics: {
          default: 'arcade',
          arcade: {
            gravity: { y: 100 }
          }
        }
      };
       miniMapConfig = {
        type: Phaser.CANVAS,
        height: 300,
        width: 300,
        scene: [ MiniMapScene ],
        parent: 'miniMapContainer',
        physics: {
          default: 'arcade',
          arcade: {
            gravity: { y: 100 }
          }
        }
      };
    }
    ngOnInit() {

      if (this.villagesList != undefined)
      {
            this.coordx = this.mapInfoList.villageList.selectedVillage.x.toString();
            this.coordy = this.mapInfoList.villageList.selectedVillage.y.toString();
      }
      eventsCenter.on('redirect-village', this.redirectvillage, this);
      eventsCenter.on('redirect-field', this.redirectField, this);
      // eventsCenter.on('show-modal', this.showModal, this);
      // eventsCenter.on('show-modal-build', this.showModalBuild, this);
      // eventsCenter.on('show-modal-send-troops', this.showModalSendTroops, this);
      // eventsCenter.on('show-modal-send-workers', this.showModalSendWorkers, this);
      eventsCenter.on('show-modal-field-details', this.showInfoFieldDetails, this);
      // eventsCenter.on('show-modal-send-sources', this.showModalSendSources, this);
      // eventsCenter.on('show-modal-send-message', this.showModalSendMessage, this);
      // eventsCenter.on('show-modal-village-details', this.showModalVillageDetails, this);
      eventsCenter.on('show-info-send-troops', this.showInfoSendTroops, this);
      eventsCenter.on('show-info-send-workers', this.showInfoSendWorkers, this);
      eventsCenter.on('show-info-build', this.showInfoBuild, this);
      //eventsCenter.on('show-info-send-workers', this.showInfoSendWorkers, this);
      //eventsCenter.on('village-list-not-loaded', this.villageListNotLoaded, this);
      //eventsCenter.on('map-info-list-not-loaded', this.mapInfoListNotLoaded, this);
      eventsCenter.on('close-modals', this.closeModals, this);
      //eventsCenter.on('update-load-villages', this.updateLoadVillages, this);
    }

    // ngAfterContentInit()
    // {
    //   setTimeout(() => this.loadMap(),1000);
    // }

    loadMap()
    {
      phaserGame = new Phaser.Game(mapConfig);
      //phaserGameDetail = new Phaser.Game(this.mapConfigDetail);
      phaserGameMiniMap = new Phaser.Game(miniMapConfig);
      phaserGameMiniMap.canvas.id = "MiniMap";
    }

    ngAfterViewInit()
    {
      document.onclick = (args:any) : void =>
      {
        if (args.target.tagName === 'NGB-MODAL-BACKDROP')
        {
           this.closeModals();
        }
      }

      // this.modalRefSendTroops?.closed.subscribe((result) => {
      //   if (result === 'redirect') {
      //       this.showInfoSendTroops();
      //   }
      // });
    }

    ngOnDestroy() {
      phaserGameMiniMap.destroy(true);
      //phaserGame.destroy(true);
      //phaserGameDetail.destroy(true);
      // if (this.phaserGame.scene.isActive('main')) {
      //   this.phaserGame.scene.stop('main');
      //   this.phaserGame.scene.remove('main');
      // }
      // if (phaserGame.scene.isActive('detailMap')) {
      //   phaserGame.scene.stop('detailMap');
      //   phaserGame.scene.remove('detailMap');
      // }
      phaserGame.destroy(true);
      const sendTroopsContainer = document.getElementById('sendTroopsContainer');
      if (sendTroopsContainer) {
          sendTroopsContainer.innerHTML = ''; // Clear content if needed
      }
    }

    closeModals()
    {
      // if (this.modalRefSendTroops != undefined) 
      //   {
      //     this.modalRefSendTroops.close();
      //     this.modalRefSendTroops = null;
      //   }
      // if (this.modalRefBuild != undefined) this.modalRefBuild.close();
      // if (this.modalRefSendWorkers != undefined) this.modalRefSendWorkers.close();
      // if (this.modalRefFieldDetails != undefined) this.modalRefFieldDetails.close();
      // if (this.modalRefSendMessage != undefined) this.modalRefSendMessage.close();
      // if (this.modalRefSendSources != undefined) this.modalRefSendSources.close();
      // if (this.modalRefVillageDetails != undefined) this.modalRefVillageDetails.close();
      // if (this.modalRefSendTroops != undefined) {
      //   this.modalRefSendTroops.dismiss();
      //   this.modalRefSendTroops.close();
      //   this.modalRefSendTroops = null;
      // }
      // if (this.modalRefBuild != undefined) {
      //   this.modalRefBuild.dismiss();
      //   this.modalRefBuild.close();
      //   this.modalRefBuild = null;
      // }
      // if (this.modalRefSendWorkers != undefined) {
      //   this.modalRefSendWorkers.dismiss();
      //   this.modalRefSendWorkers.close();
      //   this.modalRefSendWorkers = null;
      // }
      // if (this.modalRefFieldDetails != undefined) {
      //   this.modalRefFieldDetails.close();
      //   this.modalRefFieldDetails.close();
      //   this.modalRefFieldDetails = null;
      // }
      // if (this.modalRefSendMessage != undefined) {
      //   this.modalRefSendMessage.close();
      //   this.modalRefSendMessage = null;
      // }
      // if (this.modalRefSendSources != undefined) {
      //   this.modalRefSendSources.close();
      //   this.modalRefSendSources = null;
      // }
      // if (this.modalRefVillageDetails != undefined) {
      //   this.modalRefVillageDetails.close();
      //   this.modalRefVillageDetails = null;
      // }
      this.modalVillageDetails.dismissAll();
      // this.modalSendTroops.dismissAll();
      // this.modalSendBuild.dismissAll();
      // this.modalSendWorkers.dismissAll();
      // this.modalFieldDetails.dismissAll();
      // this.modalSendMessage.dismissAll();
      // this.modalSendSources.dismissAll();
    }

    closeModalInfoSendWorkers()
    {
      if (this.modalRefInfoSendWorkers)
      {
        this.modalRefInfoSendWorkers.dismiss();
        this.modalRefInfoSendWorkers = null;
      }
    }

    closeModalInfoSendTroops()
    {
      if (this.modalRefInfoSendTroops)
      {
        this.modalRefInfoSendTroops.dismiss();
        this.modalRefInfoSendTroops = null;
      }
    }

    // loadVillages(retry: number)
    // {
    //   if (this.villagesList != undefined)
    //   {
    //       eventsCenter.emit('load-villages', this.villagesList);
    //   }
    //   else
    //   {
    //       if (retry < 11)
    //       {
    //         retry++;
    //         setTimeout(() => this.loadVillages(retry),100);
    //       }
    //   }
    // }

    // loadMapInfo(retry: number)
    // {
    //   if (this.mapInfoList != undefined)
    //   {
    //       eventsCenter.emit('load-map-info', this.mapInfoList);
    //   }
    //   else
    //   {
    //       if (retry < 11)
    //       {
    //         retry++;
    //         setTimeout(() => this.loadMapInfo(retry),100);
    //       }
    //   }
    // }

    // updateLoadVillages()
    // {
    //   setTimeout(() => {this.loadVillages(1);},100);
    // }

    // villageListNotLoaded()
    // {
    //   setTimeout(() => this.loadVillages(1),100);
    // }

    // mapInfoListNotLoaded()
    // {
    //   setTimeout(() => this.loadMapInfo(1),100);
    // }

    centerMap()
    {
      eventsCenter.emit('center-mini-map', [this.coordx, this.coordy]);
      eventsCenter.emit('center-main-map', [this.coordx, this.coordy]);
    }

    changeMapZoom()
    {
      if (isZoomMap == false)
      {
        eventsCenter.emit('change-map-detail', isZoomMap);
        //setTimeout(() => this.loadVillages(1),500);
        isZoomMap = true;
        this.isZoomMapValue = true;
      }
      else
      {
        eventsCenter.emit('change-map-main', isZoomMap);
        //setTimeout(() => this.loadVillages(1),500);
        isZoomMap = false;
        this.isZoomMapValue = false;
      }
    }

    changecoordx(e)
    {
      this.coordx = e.target.value;
    }

    changecoordy(e)
    {
      this.coordy = e.target.value;
    }

    redirectvillage(coord: any)
    {
      this.router.navigate(['/village/details', coord[0], coord[1]]);
    }

    redirectField(coord: any)
    {
      this.router.navigate(['/maps/field', coord[0], coord[1]]);
    }

    showModal(p)
    {
      this.appendCss(p.x + 300, p.y + 150, 'custom-class-modal-test', 'sources', false);
      this.modalRef = this.modalService.open(ShowFieldModalComponent, {
        windowClass: 'custom-class-modal-test'
      });

    }

    showInfoSendTroops(p, xDifference, yDifference, highlight)
    {
      this.modalRefInfoSendTroops = null;
      // this.actualDetailFieldX = ((p.worldX - xDifference)/32+1)/3;
      // this.actualDetailFieldY = ((p.worldY - yDifference)/32+1)/3;
      this.actualDetailFieldX = xDifference;
      this.actualDetailFieldY = yDifference;
      //this.appendCss(300, 150, 'custom-class-modal-test');
      this.modalRefInfoSendTroops = this.modalInfoSendTroops.open(ModalSendTroopsComponent);
      this.modalRefInfoSendTroops.componentInstance.my_modal_title = "Wyślij wojska"
      this.modalRefInfoSendTroops.componentInstance.target_coordx = this.actualDetailFieldX;
      this.modalRefInfoSendTroops.componentInstance.target_coordy = this.actualDetailFieldY;
      if (this.modalRefInfoSendTroops) 
      {
        this.modalRefInfoSendTroops.result.then((result) => {
          //this.closeModalInfoSendTroops();
        }, (reason) => {
          //this.closeModalInfoSendTroops();
          this.modalInfoSendTroops.dismissAll();
        });
      }
    }

    showInfoSendWorkers(p, xDifference, yDifference, highlight)
    {
      this.modalRefInfoSendWorkers = null;
      // this.actualDetailFieldX = ((p.worldX - xDifference)/32+1)/3;
      // this.actualDetailFieldY = ((p.worldY - yDifference)/32+1)/3;
      this.actualDetailFieldX = xDifference;
      this.actualDetailFieldY = yDifference;
      //this.appendCss(300, 150, 'custom-class-modal-test');
      this.modalRefInfoSendWorkers = this.modalInfoSendWorkers.open(ModalSendWorkersComponent);
      this.modalRefInfoSendWorkers.componentInstance.my_modal_title = "Zbieraj surowce"
      this.modalRefInfoSendWorkers.componentInstance.target_coordx = this.actualDetailFieldX;
      this.modalRefInfoSendWorkers.componentInstance.target_coordy = this.actualDetailFieldY;
      this.modalRefInfoSendWorkers.result.then((result) => {
        //this.closeModalInfoSendWorkers();
      }, (reason) => {
        //this.closeModalInfoSendWorkers();
        this.modalInfoSendWorkers.dismissAll();
      });
    }

    showInfoBuild(p, xDifference, yDifference, highlight)
    {
      this.modalRefBuild = null;
      //this.appendCss(300, 150, 'custom-class-modal-test');
      this.actualDetailFieldX = xDifference;
      this.actualDetailFieldY = yDifference;
      this.modalRefBuild = this.modalInfoBuild.open(ModalBuildComponent);
      this.modalRefBuild.componentInstance.my_modal_title = "Buduj usprawnienia"
      this.modalRefBuild.componentInstance.target_coordx = this.actualDetailFieldX;
      this.modalRefBuild.componentInstance.target_coordy = this.actualDetailFieldY;
      this.modalRefBuild.result.then((result) => {
        //this.closeModalInfoSendWorkers();
      }, (reason) => {
        //this.closeModalInfoSendWorkers();
        this.modalInfoBuild.dismissAll();
      });
    }

    showInfoFieldDetails(p, xDifference, yDifference, highlight)
    {
      this.modalRefFieldDetails = null;
      this.actualDetailFieldX = xDifference;
      this.actualDetailFieldY = yDifference;
      this.modalRefFieldDetails = this.modalInfoBuild.open(ModalFieldDetailsComponent);
      this.modalRefFieldDetails.componentInstance.my_modal_title = "Pole info"
      this.modalRefFieldDetails.componentInstance.target_coordx = this.actualDetailFieldX;
      this.modalRefFieldDetails.componentInstance.target_coordy = this.actualDetailFieldY;
      this.modalRefFieldDetails.result.then((result) => {
      }, (reason) => {
        this.modalFieldDetails.dismissAll();
      });
    }

    // showModalSendTroops(p, xDifference, yDifference, highlight)
    // {
    //   this.modalRefSendTroops = null;
    //   var offsetTop = document.getElementById("mapContainer").offsetTop;
    //   var offsetLeft = document.getElementById("mapContainer").offsetLeft;
    //   this.appendCss(p.x - xDifference + offsetLeft - 16, p.y - yDifference + offsetTop - 16, 'modal-send-troops', 'attack', highlight);
    //   this.modalRefSendTroops = this.modalSendTroops.open(ShowFieldModalSendTroopsComponent, {
    //     windowClass: 'modal-send-troops'
    //   });

    //   this.actualDetailFieldX = ((p.worldX - xDifference)/32+1)/3;
    //   this.actualDetailFieldY = ((p.worldY - yDifference)/32+1)/3;

    //   this.modalRefSendTroops.result.then((result) => {
    //   }, (reason) => {
    //     this.closeModals();
    //     this.showInfoSendTroops();
    //   });

    // }

    // showModalBuild(p, xDifference, yDifference, highlight)
    // {
    //   this.modalRefBuild = null;
    //   var offsetTop = document.getElementById("mapContainer").offsetTop;
    //   var offsetLeft = document.getElementById("mapContainer").offsetLeft;
    //   this.appendCss(p.x - xDifference + offsetLeft - 16, p.y - yDifference + offsetTop + 48, 'modal-build', 'build', highlight);
    //   this.modalRefBuild= this.modalSendBuild.open(ShowFieldModalBuildComponent, {
    //     windowClass: 'modal-build'
    //   });

    //   this.actualDetailFieldX = ((p.worldX - xDifference)/32+1)/3;
    //   this.actualDetailFieldY = ((p.worldY - yDifference)/32+1)/3;

    //   this.modalRefBuild.result.then((result) => {

    //   }, (reason) => {
    //     this.closeModals();
    //     this.showInfoBuild();
    //   });
    // }

    // showModalSendWorkers(p, xDifference, yDifference, highlight)
    // {
    //   var offsetTop = document.getElementById("mapContainer").offsetTop;
    //   var offsetLeft = document.getElementById("mapContainer").offsetLeft;
    //   this.appendCss(p.x - xDifference + offsetLeft + 48, p.y - yDifference + offsetTop - 16, 'modal-send-workers', 'sources', highlight);
    //   this.modalRefSendWorkers = this.modalSendWorkers.open(ShowFieldModalSendWorkersComponent, {
    //     windowClass: 'modal-send-workers',
    //     ariaLabelledBy: 'modal-basic-title',
    //     centered: true
    //   });

    //   this.actualDetailFieldX = ((p.worldX - xDifference)/32+1)/3;
    //   this.actualDetailFieldY = ((p.worldY - yDifference)/32+1)/3;

    //   this.modalRefSendWorkers.result.then((result) => {

    //   }, (reason) => {
    //     this.closeModals();

    //     setTimeout(() => {
    //       this.showInfoSendWorkers();
    //   }, 500);


    //     //this.modalService.dismissAll();
    //   });

    //   // setTimeout(() => {
    //   //   // Query all ngb-modal-window elements
    //   //   const modalWindows = document.querySelectorAll('ngb-modal-window');
        
    //   //   // Loop through all modal windows and set aria-hidden="false"
    //   //   modalWindows.forEach(modalWindow => {
    //   //     modalWindow.setAttribute('aria-hidden', 'false');
    //   //   });
    //   // }, 100); // Wait for the modal to be fully rendered

    //   // setTimeout(() => {
    //   //   const modalContent = document.querySelector('.modal-send-workers');
    //   //   if (modalContent) {
    //   //     modalContent.setAttribute('aria-hidden', 'false'); 
    //   //     //(modalContent as HTMLElement).focus();
    //   //     modalContent.addEventListener('click', (event) => {
    //   //       // Handle click event for the entire modal content
    //   //       console.log('Modal clicked:', event);
    
    //   //       // Example: dismiss the modal on click
    //   //       this.showInfoSendWorkers();
    //   //       //modalContent.removeAttribute('inert');
    //   //     });
    //   //     //modalContent.setAttribute('inert', 'true');
    //   //   }
    //   // }, 500);
    // }

    showModalFieldDetails(p, xDifference, yDifference, highlight)
    {
      this.modalRefFieldDetails = null;
      var offsetTop = document.getElementById("mapContainer").offsetTop;
      var offsetLeft = document.getElementById("mapContainer").offsetLeft;
      this.appendCss(p.x - xDifference + offsetLeft + 48, p.y - yDifference + offsetTop + 48, 'modal-field-details', 'fieldDetail', highlight);
      this.modalRefFieldDetails = this.modalFieldDetails.open(ShowFieldModalComponent, {
        windowClass: 'modal-field-details'
      });
    }

    showModalSendMessage(p, xDifference, yDifference, highlight)
    {
      this.modalRefSendMessage = null;
      var offsetTop = document.getElementById("mapContainer").offsetTop;
      var offsetLeft = document.getElementById("mapContainer").offsetLeft;
      this.appendCss(p.x - xDifference + offsetLeft - 16, p.y - yDifference + offsetTop + 48, 'modal-send-message', 'message', highlight);
      this.modalRefSendMessage= this.modalSendMessage.open(ShowFieldModalComponent, {
        windowClass: 'modal-send-message'
      });
    }

    showModalSendSources(p, xDifference, yDifference, highlight)
    {
      this.modalRefSendSources = null;
      var offsetTop = document.getElementById("mapContainer").offsetTop;
      var offsetLeft = document.getElementById("mapContainer").offsetLeft;
      this.appendCss(p.x - xDifference + offsetLeft + 48, p.y - yDifference + offsetTop - 16, 'modal-send-sources', 'sources', highlight);
      this.modalRefSendSources = this.modalSendSources.open(ShowFieldModalComponent, {
        windowClass: 'modal-send-sources'
      });
    }

    showModalVillageDetails(p, xDifference, yDifference, highlight)
    {
      this.modalRefVillageDetails= null;
      var offsetTop = document.getElementById("mapContainer").offsetTop;
      var offsetLeft = document.getElementById("mapContainer").offsetLeft;
      this.appendCss(p.x - xDifference + offsetLeft + 48, p.y - yDifference + offsetTop + 48, 'modal-village-details', 'castle', highlight);
      this.modalRefVillageDetails= this.modalVillageDetails.open(ShowFieldModalComponent, {
        windowClass: 'modal-village-details'
      });
    }

    appendCss(pointerX, pointerY, className, imageName, highlight)
    {
        var animation = '';
        if (highlight)
        {
          animation = ' animation: glowingMapDialog 1300ms infinite;'
        }
        var head = document.head || document.getElementsByTagName('head')[0];
        let text = '.' + className + ' { background-image: url("./assets/icons/' + imageName + '.png"); aria-hidden=false; width: 24px; height: 24px; position: absolute; top: ' + pointerY + 'px; right: 0px; bottom: 0;left: ' + pointerX + 'px;overflow-y: hidden;z-index: 1060; ' + animation + '}';

        var style = document.createElement('style');
        style.type = 'text/css';
        style.appendChild(document.createTextNode(text));
        head.appendChild(style);
    }
}

var eventsCenter = new Phaser.Events.EventEmitter();
var mapInfoListShared: MapInfoList;
var xShared: any;
var yShared: any;
var isZoomMap: any;
var phaserGame: Phaser.Game;
var phaserGameDetail: Phaser.Game;
var phaserGameMiniMap: Phaser.Game;
var mapConfig: Phaser.Types.Core.GameConfig;
//var mapConfigDetail: Phaser.Types.Core.GameConfig;
var miniMapConfig: Phaser.Types.Core.GameConfig;

class MainScene extends Phaser.Scene {
    constructor() {
      super({ key: 'main' });
      //this.villagesList = JSON.parse(localStorage.getItem('villages'));
    }

    text: any;
    villagesList: VillagesList;
    tooltipText: any;
    layer: any;
    map: any;
    village: any;
    updateTimer: any;
    isSceneLoaded: any;

    preload() {
      this.load.tilemapTiledJSON('map', 'assets/maps/smallMap.json');
      this.load.image('tiles', 'assets/maps/MapGraphics.png');
      this.load.image('village', 'assets/maps/castle.png');
      this.load.image('wheatFarm', 'assets/maps/WheatFarm.png');
      // this.load.image('railway', 'assets/maps/Railway.png');
      // this.load.image('p00010000', 'assets/maps/P00010000.png');
      // this.load.image('p00100001', 'assets/maps/P00100001.png');
      // this.load.image('p00100010', 'assets/maps/P00100010.png');
      // this.load.image('sawmill', 'assets/maps/Sawmill.png');
      // this.load.image('clayMine', 'assets/maps/ClayMine.png');
      // this.load.image('coalMine', 'assets/maps/CoalMine.png');
      // this.load.image('cornFarm', 'assets/maps/CornFarm.png');
      // this.load.image('cowsFarm', 'assets/maps/CowsFarm.png');
      // this.load.image('fortress', 'assets/maps/Fortress.png');
      // this.load.image('goldMine', 'assets/maps/GoldMine.png');
      // this.load.image('quarry', 'assets/maps/Quarry.png');
      // this.load.image('sheepFarm', 'assets/maps/SheepFarm.png');
      // this.load.image('shepherdsHut', 'assets/maps/ShepherdsHut.png');
      // this.load.image('silverMine', 'assets/maps/SilverMine.png');
      //this.village = undefined;
      this.isSceneLoaded = false;
    }

    create() {
      var map = this.make.tilemap({ key: 'map', tileWidth: 32, tileHeight: 32 });
      var tileset = map.addTilesetImage('tiles');
      var layer = map.createLayer(0, tileset, 0, 0); // layer index, tileset, x, y
      layer.skipCull = true;

      this.villagesList = mapInfoListShared.villageList;
      this.loadVillages(mapInfoListShared.villageList);

      // if (this.isSceneLoaded == false)
      // {
      //   eventsCenter.emit('update-load-villages', null);
      // }

      this.map = map;
      // this.updateTimer = -100;

      // for (var i = 0; i < this.villagesList.villages.length; i++)
      // {
      //   var spriteVillage = this.villagesList.villages[i];
      //   this.add.sprite(spriteVillage.coords.x*32-16, spriteVillage.coords.y*32-16, 'village').setInteractive();
      // }



      eventsCenter.on('update-scroll-main', this.updateScrollMain, this);
      eventsCenter.on('click-mini-map', this.clickMiniMap, this);
      eventsCenter.on('center-main-map', this.centerMainMap, this);
      eventsCenter.on('change-map-detail', this.changeToDetailMap, this);
      //eventsCenter.on('load-villages', this.loadVillages, this);

      var cam = this.cameras.main;
      cam.zoom = 1;

        var tooltip = this.setTooltip();
        tooltip.visible = false;

        var tooltipTextLocal = this.tooltipText;

      this.input.on('gameobjectover', function (pointer, gameObject) {
        var village = this.villagesList.villages.find((x: { coords: { x: any; y: any; }; }) => x.coords.x === (gameObject.x/32 + 0.5) && x.coords.y === (gameObject.y/32 + 0.5));
        tooltipTextLocal.setText([village.name + '(' + village.coords.x + '|' + village.coords.y + ')',
        'Points: ' + village.points,
        'Owner: ' + village.ownerName])
        tooltip.setPosition(gameObject.x + 14, gameObject.y - 10);
        tooltip.visible = true;
        this.children.bringToTop(tooltip);
      }, this);


      this.input.on('pointerout', function () {
        tooltip.visible = false;
      });

      cam.setBounds(0, 0, 3200, 3200);

      this.cameras.main.centerOn(Math.trunc(xShared*32),Math.trunc(yShared*32)); 

      this.input.on("pointermove", function (p) {
        if (!p.isDown) return;
    
        eventsCenter.emit('update-scroll-mini-map', p);
        cam.scrollX -= (p.x - p.prevPosition.x) / cam.zoom;
        cam.scrollY -= (p.y - p.prevPosition.y) / cam.zoom;

        var xMoved = (p.x - p.prevPosition.x)/ cam.zoom / 32;
        var yMoved = (p.y - p.prevPosition.y) / cam.zoom / 32;
        console.log("xMoved: " + xMoved + "yMoved: " + yMoved);

        //xShared = Math.trunc((p.worldX)/(32));
        //yShared = Math.trunc((p.worldY)/(32));
        xShared -= xMoved;
        yShared -= yMoved;
        console.log("X: " + Math.trunc(xShared) + "Y: " + Math.trunc(yShared));
      });


      this.input.on("gameobjectup", function (pointer, gameObject)
      {
         if (gameObject.texture.key == 'village')
         {
            eventsCenter.emit('redirect-village', [Math.ceil(pointer.worldX/32),Math.ceil(pointer.worldY/32)]);
         }
         if (gameObject.texture.key == 'field')
         {
            eventsCenter.emit('redirect-field', [Math.ceil(pointer.worldX/32),Math.ceil(pointer.worldY/32)]);
         }
      });
    }

    updateScrollMain(p)
    {
      this.cameras.main.scrollX -= (p.x - p.prevPosition.x);
      this.cameras.main.scrollY -= (p.y - p.prevPosition.y);
    }

    clickMiniMap(p)
    {
      //if (this.cameras != undefined && this.cameras.main != undefined) this.cameras.main.centerOn(Math.trunc((((p.worldX)*32)/6)), Math.trunc((((p.worldY)*32)/6)));
      if (this.cameras != undefined && this.cameras.main != undefined) this.cameras.main.centerOn(Math.trunc(xShared*32), Math.trunc(yShared*32)); 
    }

    changeToDetailMap(f)
    {
      //phaserGame.destroy(true);
      //phaserGameDetail = new Phaser.Game(mapConfigDetail);
      this.time.delayedCall(100, this.scene.switch, ['detailMap'], this.scene);
      eventsCenter.emit('click-mini-map-to-detail', null);
      //this.scene.start("detailMap");
      //if (this.cameras != undefined && this.cameras.main != undefined) this.cameras.main.centerOn(Math.trunc(xShared*32), Math.trunc(yShared*32));
    }

    centerMainMap(f)
    {
      //this.cameras.main.centerOn(f[0] * 32, f[1] * 32);
      if (this.cameras != undefined && this.cameras.main != undefined) this.cameras.main.centerOn(Math.trunc(xShared*32), Math.trunc(yShared*32));   
    }

    setTooltip(): Phaser.GameObjects.Container
    {
      const tooltip = { text: ['wioska Spontan231 (50|50)',
      'Points: 300',
      'Owner: Spontan231']}
        
      const tooltipX = 0 //village.getRightCenter().x
      const tooltipY = 0  // village.y - 12
      const textPadding = 10
      
      this.tooltipText = this.add.text(textPadding, textPadding, tooltip.text, 
        { font: "18px monospace", color: '#000' });
      const background = this.add.rectangle(0, 0, this.tooltipText.displayWidth + (textPadding * 2), this.tooltipText.displayHeight + (textPadding * 2), 0xFFD855).setOrigin(0, 0)
      
      // Put both text and background in a cointainer to easily position them
      const tooltipContainer = this.add.container(tooltipX, tooltipY)
      tooltipContainer.add(background)
      tooltipContainer.add(this.tooltipText)
      return tooltipContainer;
    }

    loadVillages(villagesList : any)
    {
      this.isSceneLoaded = true;
      if (this.children.getChildren().find(v => v.name == 'village') == undefined)
      {
        for (var i = 0; i < villagesList.villages.length; i++)
        {
          var spriteVillage = villagesList.villages[i];
          this.village = this.add.sprite(spriteVillage.coords.x*32-16, spriteVillage.coords.y*32-16, 'village').setInteractive();
          this.village.name = 'village';
          var villageColor = 0x808080; //gray
          //FFFFFF white
          //if (spriteVillage.coords.x == 50 && spriteVillage.coords.y == 50) villageColor = 0x0094FF;
          if (spriteVillage.coords.x == villagesList.selectedVillage.x && spriteVillage.coords.y == villagesList.selectedVillage.y) villageColor = 0xFFFFFF;
          else villageColor = 0x7F3300;
          // if (spriteVillage.coords.x == 59 && spriteVillage.coords.y == 51) villageColor = 0x7F3300;
          // if (spriteVillage.coords.x == 57 && spriteVillage.coords.y == 59) villageColor = 0x7F3300;
          // if (spriteVillage.coords.x == 50 && spriteVillage.coords.y == 56) villageColor = 0x7F3300;
          // if (spriteVillage.coords.x == 52 && spriteVillage.coords.y == 55) villageColor = 0x7F3300;
          // if (spriteVillage.coords.x == 53 && spriteVillage.coords.y == 56) villageColor = 0x7F3300;
          this.add.rectangle(spriteVillage.coords.x*32-32, spriteVillage.coords.y*32-1, 32, 3, villageColor).setOrigin(0, 0)
        }
        this.villagesList = villagesList;
      }
    }

    update() {
      // if (this.updateTimer == 30)
      // {
      //    this.add.sprite(51*32-16, 51*32-16, 'p00100001').setInteractive();
      // }
      // if (this.updateTimer == 35)
      // {
      //    this.add.sprite(52*32-16, 51*32-16, 'p00100010').setInteractive();
      // }
      // if (this.updateTimer == 40)
      // {
      //    this.add.sprite(53*32-16, 51*32-16, 'p00100010').setInteractive();
      // }
      // if (this.updateTimer == 45)
      // {
      //    this.add.sprite(54*32-16, 51*32-16, 'p00100010').setInteractive();
      // }
      // if (this.updateTimer == 50)
      // {
      //    this.add.sprite(52*32-16, 53*32-16, 'clayMine').setInteractive();
      // }

      // if (this.updateTimer == 50)
      // {
      //    this.add.sprite(48*32-16, 50*32-16, 'sawmill').setInteractive();
      // }
      // if (this.updateTimer == 55)
      // {
      //    this.add.sprite(52*32-16, 52*32-16, 'sawmill').setInteractive();
      // }
      // if (this.updateTimer == 60)
      // {
      //    this.add.sprite(49*32-16, 49*32-16, 'clayMine').setInteractive();
      // }
      // if (this.updateTimer == 65)
      // {
      //    this.add.sprite(48*32-16, 51*32-16, 'wheatFarm').setInteractive();
      // }
      // if (this.updateTimer == 70)
      // {
      //    this.add.sprite(49*32-16, 52*32-16, 'wheatFarm').setInteractive();
      // }
      // if (this.updateTimer == 75)
      // {
      //    this.add.sprite(46*32-16, 49*32-16, 'wheatFarm').setInteractive();
      // }
      // if (this.updateTimer == 80)
      // {
      //    this.add.sprite(45*32-16, 49*32-16, 'wheatFarm').setInteractive();
      // }
      // if (this.updateTimer == 85)
      // {
      //    this.add.sprite(45*32-16, 51*32-16, 'cornFarm').setInteractive();
      // }
      // if (this.updateTimer == 90)
      // {
      //    this.add.sprite(46*32-16, 52*32-16, 'cornFarm').setInteractive();
      // }
      // if (this.updateTimer == 95)
      // {
      //    this.add.sprite(46*32-16, 51*32-16, 'cornFarm').setInteractive();
      // }

      //  if (this.updateTimer == 100)
      //  {
      //     this.add.sprite(47*32-16, 49*32-16, 'wheatFarm').setInteractive();
      //  }
      //  if (this.updateTimer == 105)
      //  {
      //     this.add.sprite(50*32-16, 51*32-16, 'railway').setInteractive();
      //  }
      //  if (this.updateTimer == 110)
      //  {
      //     this.add.sprite(50*32-16, 52*32-16, 'railway').setInteractive();
      //  }
      //  if (this.updateTimer == 115)
      //  {
      //     this.add.sprite(50*32-16, 53*32-16, 'railway').setInteractive();
      //  }
      //  if (this.updateTimer == 120)
      //  {
      //     this.add.sprite(50*32-16, 54*32-16, 'railway').setInteractive();
      //  }
      //  if (this.updateTimer == 125)
      //  {
      //     this.add.sprite(50*32-16, 55*32-16, 'railway').setInteractive();
      //  }

      //  if (this.updateTimer == 130)
      //  {
      //     this.add.sprite(48*32-16, 54*32-16, 'sheepFarm').setInteractive();
      //  }
      //  if (this.updateTimer == 135)
      //  {
      //     this.add.sprite(49*32-16, 55*32-16, 'sheepFarm').setInteractive();
      //  }
      //  if (this.updateTimer == 140)
      //  {
      //     this.add.sprite(52*32-16, 48*32-16, 'coalMine').setInteractive();
      //  }
      //  if (this.updateTimer == 145)
      //  {
      //     this.add.sprite(54*32-16, 49*32-16, 'coalMine').setInteractive();
      //  }
      //  if (this.updateTimer == 150)
      //  {
      //     this.add.sprite(54*32-16, 53*32-16, 'cowsFarm').setInteractive();
      //  }
      //  if (this.updateTimer == 155)
      //  {
      //     this.add.sprite(55*32-16, 54*32-16, 'cowsFarm').setInteractive();
      //  }
      //  if (this.updateTimer == 160)
      //  {
      //     this.add.sprite(58*32-16, 51*32-16, 'fortress').setInteractive();
      //  }
      //  if (this.updateTimer == 165)
      //  {
      //     this.add.sprite(51*32-16, 47*32-16, 'goldMine').setInteractive();
      //  }
      //  if (this.updateTimer == 170)
      //  {
      //     this.add.sprite(48*32-16, 55*32-16, 'quarry').setInteractive();
      //  }
      //  if (this.updateTimer == 175)
      //  {
      //     this.add.sprite(47*32-16, 56*32-16, 'quarry').setInteractive();
      //  }
      //  if (this.updateTimer == 180)
      //  {
      //     this.add.sprite(45*32-16, 55*32-16, 'shepherdsHut').setInteractive();
      //  }
      //  if (this.updateTimer == 185)
      //  {
      //     this.add.sprite(56*32-16, 49*32-16, 'silverMine').setInteractive();
      //  }

      //  var villageColor = 0x0094FF

      //  if (this.updateTimer == 250)
      //  {
      //     this.add.rectangle(49*32-32, 54*32-1, 32, 3, villageColor).setOrigin(0, 0)
      //  }
      //  if (this.updateTimer == 270)
      //  {
      //     this.add.rectangle(47*32-32, 51*32-1, 32, 3, villageColor).setOrigin(0, 0)
      //  }
      //  if (this.updateTimer == 290)
      //  {
      //     this.add.rectangle(46*32-32, 53*32-1, 32, 3, villageColor).setOrigin(0, 0)
      //  }
      //  if (this.updateTimer == 310)
      //  {
      //     this.add.rectangle(55*32-32, 51*32-1, 32, 3, villageColor).setOrigin(0, 0)
      //  }
      //  if (this.updateTimer == 330)
      //  {
      //     this.add.rectangle(56*32-32, 51*32-1, 32, 3, villageColor).setOrigin(0, 0)
      //  }
      //  if (this.updateTimer == 350)
      //  {
      //     this.add.rectangle(56*32-32, 52*32-1, 32, 3, villageColor).setOrigin(0, 0)
      //  }
      //  if (this.updateTimer == 370)
      //  {
      //     this.add.rectangle(49*32-32, 54*32-1, 32, 3, villageColor).setOrigin(0, 0)
      //  }

      //  if (this.updateTimer == 470)
      //  {
      //     this.add.rectangle(56*32-32, 55*32-1, 32, 3, villageColor).setOrigin(0, 0)
      //  }
      //  if (this.updateTimer == 490)
      //  {
      //     this.add.rectangle(57*32-32, 59*32-1, 32, 3, villageColor).setOrigin(0, 0)
      //  }
      //  if (this.updateTimer == 510)
      //  {
      //     this.add.rectangle(50*32-32, 56*32-1, 32, 3, villageColor).setOrigin(0, 0)
      //  }
      //  if (this.updateTimer == 530)
      //  {
      //     this.add.rectangle(52*32-32, 55*32-1, 32, 3, villageColor).setOrigin(0, 0)
      //  }
      //  if (this.updateTimer == 550)
      //  {
      //     this.add.rectangle(53*32-32, 56*32-1, 32, 3, villageColor).setOrigin(0, 0)
      //  }


      // this.updateTimer++;
    }    
  }

  class MiniMapScene extends Phaser.Scene {
    constructor() {
      super({ key: 'miniMap' });
      //this.villagesList = JSON.parse(localStorage.getItem('villages'));
    }

    text: any;
    villagesList: VillagesList;
    tooltipText: any;
    isSceneLoaded: any;

    preload() {
      this.load.image('miniMap', 'assets/maps/MiniMap.png');
      this.isSceneLoaded = false;
    }

    create() {
      var map = this.make.image({ key: 'miniMap' });

      this.villagesList = mapInfoListShared.villageList;
      this.loadVillages(mapInfoListShared.villageList);

      // for (var i = 0; i < this.villagesList.villages.length; i++)
      // {
      //   var spriteVillage = this.villagesList.villages[i];
      //   const background = this.add.rectangle(spriteVillage.coords.x*6, spriteVillage.coords.y*6, 4, 4, 0x00FFFF).setOrigin(0, 0)    
      // }

      // if (this.isSceneLoaded == false)
      // {
      //   eventsCenter.emit('update-load-villages', null);
      // }

      var cam = this.cameras.main;
      cam.zoom = 1;
 
      cam.setBounds(0, 0, 600, 600);

      this.cameras.main.centerOn(xShared*6, yShared*6); 
  
      eventsCenter.on('update-scroll-mini-map', this.updateScrollMiniMap, this);
      eventsCenter.on('center-mini-map', this.centerMiniMap, this);
      //eventsCenter.on('load-villages', this.loadVillages, this);

      this.input.on("pointermove", function (p) {
        if (!p.isDown) return;
        eventsCenter.emit('update-scroll-main', p);

        cam.scrollX -= ((p.x - p.prevPosition.x)*6/32) / cam.zoom;
        cam.scrollY -= ((p.y - p.prevPosition.y)*6/32) / cam.zoom;
      });

      var pX = 0;
      var pY = 0;

      this.input.on("pointerdown", function (p)
      {
        pX = p.x;
        pY = p.y;
      });


      this.input.on("pointerup", function (p)
      {
          if (p.x === pX && p.y === pY)
          {

            //this.cameras.main.centerOn(Math.trunc(p.worldX), Math.trunc(p.worldY)); 
            xShared = Math.trunc(p.worldX/6);
            yShared = Math.trunc(p.worldY/6);
            console.log("clickMiniMap:" + (Math.trunc(p.worldX)) + "," + (Math.trunc(p.worldY)));
            console.log("CentorOnMiniMap:" + xShared + "," + yShared);
            this.cameras.main.centerOn(Math.trunc(xShared*6), Math.trunc(yShared*6)); 

            if (isZoomMap)
            {
              eventsCenter.emit('click-mini-map-to-detail', p);
            }
            else
            {      
              eventsCenter.emit('click-mini-map', p);
            }
          }
      });
    }

    update() {
      //console.log('update method');
    }    

    loadVillages(villagesList : any)
    {
      this.isSceneLoaded = true;
      for (var i = 0; i < villagesList.villages.length; i++)
      {
        var spriteVillage = villagesList.villages[i];
        var villageColor = 0xA0A0A0;
        if (spriteVillage.coords.x == villagesList.selectedVillage.x && spriteVillage.coords.y == villagesList.selectedVillage.y) villageColor = 0xFFFFFF;
        else villageColor = 0x7F3300;
        // if (spriteVillage.coords.x == 50 && spriteVillage.coords.y == 50) villageColor = 0x0094FF;
        // if (spriteVillage.coords.x == 56 && spriteVillage.coords.y == 55) villageColor = 0x7F3300;
        // if (spriteVillage.coords.x == 59 && spriteVillage.coords.y == 51) villageColor = 0x7F3300;
        const background = this.add.rectangle(spriteVillage.coords.x*6, spriteVillage.coords.y*6, 4, 4, villageColor).setOrigin(0, 0)   
      }
      if (villagesList?.selectedVillage != undefined)
      {
        this.cameras.main.centerOn(villagesList?.selectedVillage.x * 6, villagesList?.selectedVillage.y * 6); 
      }
      this.villagesList = villagesList;
    }

    updateScrollMiniMap(p)
    {

      if (this.cameras != undefined && this.cameras.main != undefined) this.cameras.main.scrollX -= ((p.x - p.prevPosition.x)*6/32) / 2;
      if (this.cameras != undefined && this.cameras.main != undefined) this.cameras.main.scrollY -= ((p.y - p.prevPosition.y)*6/32) / 2;
    }

    centerMiniMap(f)
    {
      console.log('f' + f[0] + ' ' + f[1]);
      this.cameras.main.centerOn(f[0] * 6, f[1] * 6);   
    }
  }

  class DetailScene extends Phaser.Scene {
    constructor() {
      super({ key: 'detailMap' });
    }

    text: any;
    villagesList: VillagesList;
    tooltipText: any;
    map: any;
    isSceneLoaded: any;
    highlight: boolean;
    highlightSendTroops: boolean;
    highlightSendWorkers: boolean;
    graphics: any;
    interactiveImages = [];
    clickedOnPopup = false;

    preload() {
      this.load.tilemapTiledJSON('detailMapJson', 'assets/maps/DetailMap.json');
      this.load.image('tiles', 'assets/maps/MapGraphics.png');
      this.load.image('village', 'assets/maps/testVillage.png');
      // this.load.image('cows', 'assets/maps/testCows.png');
      // this.load.image('forest', 'assets/maps/testForest.png');
      // this.load.image('mountain', 'assets/maps/testMountain.png');
      // this.load.image('river', 'assets/maps/testRiver.png');
      // this.load.image('wheat', 'assets/maps/testWheat.png');
      this.load.image('railway', 'assets/maps/Railway.png');
      this.load.image('p00010000', 'assets/maps/P00010000.png');
      this.load.image('p00100001', 'assets/maps/P00100001.png');
      this.load.image('p00100010', 'assets/maps/P00100010.png');
      this.load.image('sawmill', 'assets/maps/Sawmill.png');
      this.load.image('clayMine', 'assets/maps/ClayMine.png');
      this.load.image('coalMine', 'assets/maps/CoalMine.png');
      this.load.image('cornFarm', 'assets/maps/CornFarm.png');
      this.load.image('cowsFarm', 'assets/maps/CowsFarm.png');
      this.load.image('fortress', 'assets/maps/Fortress.png');
      this.load.image('goldMine', 'assets/maps/GoldMine.png');
      this.load.image('quarry', 'assets/maps/Quarry.png');
      this.load.image('sheepFarm', 'assets/maps/SheepFarm.png');
      this.load.image('shepherdsHut', 'assets/maps/ShepherdsHut.png');
      this.load.image('silverMine', 'assets/maps/SilverMine.png');
      this.load.image('knightRed', 'assets/maps/KnightRed.png');
      this.load.image('knightBlue', 'assets/maps/KnightBlue.png');
      this.load.image('knightGrey', 'assets/maps/KnightGrey.png');
      this.load.image('knightYellow', 'assets/maps/KnightYellow.png');
      this.load.image('sources', './assets/icons/sources.png');
      this.load.image('attack', './assets/icons/attack.png');
      this.load.image('build', './assets/icons/build.png');
      this.load.image('fieldDetail', './assets/icons/fieldDetail.png');
      this.isSceneLoaded = false;
    }

    create() {
      this.map = this.make.tilemap({ key: 'detailMapJson', tileWidth: 32, tileHeight: 32 });
      var tileset = this.map.addTilesetImage('tiles');
      var layer = this.map.createLayer(0, tileset, 0, 0); // layer index, tileset, x, y
      layer.skipCull = true;

      this.highlight = false;
      this.highlightSendTroops = false;
      this.highlightSendWorkers = false;

      this.villagesList = mapInfoListShared.villageList;
      this.loadMapInfo(mapInfoListShared);



      // if (this.isSceneLoaded == false)
      // {
      //     eventsCenter.emit('map-info-list-not-loaded', null);
      // }

      eventsCenter.on('change-map-main', this.changeToMainMap, this);
      eventsCenter.on('click-mini-map-to-detail', this.clickMiniMapToDetail, this);
      //eventsCenter.on('load-map-info', this.loadMapInfo, this);

      this.add.sprite(53*32*3-16, 51*32*3-16, 'cowsFarm').setInteractive();
      this.add.sprite(48*32*3-16, 47*32*3-16, 'cornFarm').setInteractive();

      var cam = this.cameras.main;
      cam.zoom = 1;
 
      cam.setBounds(0, 0, 9600, 9600);
      
      this.cameras.main.centerOn(Math.trunc(xShared*3*32), Math.trunc(yShared*3*32)); 

      var pX = 0;
      var pY = 0;
 
      var detailMap = this.map;

      this.input.on("pointerdown", function (pointer)
      {
        this.clickedOnPopup = false;
        if (!this.clickedOnPopup && (pointer.x !== pX || pointer.y !== pY)) 
        {
          //eventsCenter.emit('close-modals', pointer);
          if (this.graphics) {
            this.graphics.destroy();
            this.graphics = null; 
          }
          this.interactiveImages.forEach(function (image) {
            image.destroy(); 
          });

          this.interactiveImages = [];
          pX = pointer.x;
          pY = pointer.y;
        }
        this.clickedOnPopup = false;
        pX = pointer.x;
        pY = pointer.y;
      }, this);


      // this.input.on("pointerup", function (p, gameObject)
      // {
      //     if (p.x === pX && p.y === pY)
      //     {
      //       var px = p.x;
      //       var py = p.y;
      //       var tile = detailMap.getTileAtWorldXY(p.worldX, p.worldY, true);
      //       var xDifference = p.worldX - tile.x * 32;
      //       var yDifference = p.worldY - tile.y * 32;
      //       if (gameObject.length > 0 && gameObject[0].texture.key == 'village')
      //       {
      //         eventsCenter.emit('show-modal-send-troops', p, xDifference, yDifference, this.highlightSendTroops);
      //         eventsCenter.emit('show-modal-send-sources', p, xDifference, yDifference, this.highlight);
      //         eventsCenter.emit('show-modal-send-message', p, xDifference, yDifference, this.highlight);
      //         eventsCenter.emit('show-modal-village-details', p, xDifference, yDifference, this.highlight);
      //       }
      //       else
      //       {
      //         eventsCenter.emit('show-modal-send-troops', p, xDifference, yDifference, this.highlightSendTroops);
      //         eventsCenter.emit('show-modal-build', p, xDifference, yDifference, this.highlight);
      //         eventsCenter.emit('show-modal-send-workers', p, xDifference, yDifference, this.highlightSendWorkers);
      //         eventsCenter.emit('show-modal-field-details', p, xDifference, yDifference, this.highlight);
      //       }
      //       if (this.graphics) {
      //         this.graphics.destroy();
      //         this.graphics = null; // Optionally, set to null to avoid future access
      //       }
      //     }
      // }, this);

    this.input.on("pointerup", function (p, gameObject) {
      var tile = detailMap.getTileAtWorldXY(p.worldX, p.worldY, true);
      console.log(customRound((tile.x + 2)/3));
      console.log(customRound((tile.y + 2)/3));
      var xDifference = p.worldX - tile.x * 32;
      var yDifference = p.worldY - tile.y * 32;
      var tileSize = 32;  
      var rectSize = 22; 

      var northWest = { x: customRound(tile.x * tileSize - tileSize + 8 + (tileSize - rectSize) / 2), y: customRound(tile.y * tileSize - tileSize + 4 + (tileSize - rectSize) / 2) };
      var northEast = { x: customRound(tile.x * tileSize + tileSize + 8 + (tileSize - rectSize) / 2), y: customRound(tile.y * tileSize - tileSize + 4 + (tileSize - rectSize) / 2) };
      var southWest = { x: customRound(tile.x * tileSize - tileSize + 8 + (tileSize - rectSize) / 2), y: customRound(tile.y * tileSize + tileSize + 4 + (tileSize - rectSize) / 2) };
      var southEast = { x: customRound(tile.x * tileSize + tileSize + 8 + (tileSize - rectSize) / 2), y: customRound(tile.y * tileSize + tileSize + 4 + (tileSize - rectSize) / 2) };

      var positions = [
          { pos: northWest, event: 'show-info-send-troops', color: null, alpha: 0, imageUrl: "attack" },
          { pos: northEast, event: 'show-info-send-workers', color: null, alpha: 0, imageUrl: "sources" },
          { pos: southWest, event: 'show-info-build', color: null, alpha: 0, imageUrl: "build" },
          { pos: southEast, event: 'show-modal-field-details', color: null, alpha: 0, imageUrl: "fieldDetail"}
      ];
  
      if (this.graphics) {
          this.graphics.destroy();
      }

      this.interactiveImages.forEach(function (image) {
        image.destroy();  
      });

      this.interactiveImages = [];

      this.graphics = this.add.graphics();
  
      positions.forEach(function (posData) {
          var { pos, event, color, alpha, imageUrl} = posData;
  
          this.graphics.fillStyle(color, alpha); 
          this.graphics.fillRect(pos.x, pos.y, rectSize, rectSize);  

          var image = this.add.image(pos.x + rectSize / 2, pos.y + rectSize / 2, imageUrl);
          image.setDisplaySize(rectSize, rectSize); 
          image.setInteractive();
  
          this.interactiveImages.push(image);

          if (this.highlightSendWorkers && event === 'show-info-send-workers') 
          {
            this.graphics = this.add.graphics({
              x: pos.x,
              y: pos.y
            })
            .fillStyle(0xffff00, 0.75)
            .fillRect(0, 0, 22, 22)
      
            this.tweens.add({targets: this.graphics, alpha: 0, ease: 'Cubic.easeOut',  duration: 700, repeat: -1, yoyo: true })
          }


          image.off('pointerdown');
          image.on('pointerdown', function (pointer) {
              console.log(`Image clicked for event: ${event}`);
              this.clickedOnPopup = true;
              eventsCenter.emit(event, p, customRound((tile.x + 2)/3), customRound((tile.y + 2)/3), this.highlight);  
              //pointer.stopPropagation();
          }, this);
      }, this);
      pX = p.x;
      pY = p.y;
  }, this);

  function customRound(value) {
    const decimal = value % 1;  // Get the decimal part of the number
    if (decimal < 0.15) {
        return Math.floor(value) + 0.0;  // Round to .0
    } else if (decimal < 0.5) {
        return Math.floor(value) + 0.3;  // Round to .3
    } else {
        return Math.floor(value) + 0.7;  // Round to .7
    }
}

      this.input.on("pointermove", function (p) {
        if (!p.isDown) return;
    
        eventsCenter.emit('update-scroll-mini-map', p);
        cam.scrollX -= (p.x - p.prevPosition.x) / cam.zoom;
        cam.scrollY -= (p.y - p.prevPosition.y) / cam.zoom;
        var xMoved = (p.x - p.prevPosition.x)/ cam.zoom / (32*3);
        var yMoved = (p.y - p.prevPosition.y) / cam.zoom / (32*3);

        xShared -= xMoved;
        yShared -= yMoved;

      });

      var tooltip = this.setTooltip();
      tooltip.visible = false;

      var tooltipTextLocal = this.tooltipText;

    this.input.on('gameobjectover', function (pointer, gameObject) {
      var village = this.villagesList.villages.find((x: { coords: { x: any; y: any; }; }) => x.coords.x === (Math.round(gameObject.x/(32*3) + (0.5/3))) && x.coords.y === (Math.round(gameObject.y/(32*3) + (0.5/3))));
      if (village != undefined)
      {
        tooltipTextLocal.setText([village.name + '(' + village.coords.x + '|' + village.coords.y + ')',
        'Points: ' + village.points,
        'Owner: ' + village.ownerName])
        var additionalX = 0;
        if (pointer.x > 400) additionalX = -230;

        tooltip.setPosition(gameObject.x + 14 + additionalX, gameObject.y - 10);
        tooltip.visible = true;
        this.children.bringToTop(tooltip);
      }
    }, this);

    this.input.on("gameobjectup", function (pointer, gameObject)
    {
      //  if (gameObject.texture.key == 'field')
      //  {
      //     eventsCenter.emit('redirect-field', gameObject.texture.key);
      //  }
      //  else
      //  {
      //   console.log(gameObject);
      //     eventsCenter.emit('show-modal', pointer);
      //     eventsCenter.emit('show-modal2', pointer);
      //  }
    });


    this.input.on('pointerout', function () {
      tooltip.visible = false;
    });

    }

    loadMapInfo(mapInfoList : any)
    {
      this.isSceneLoaded = true;
      for (var i = 0; i < mapInfoList.villageList.villages.length; i++)
      {
        var spriteVillage = mapInfoList.villageList.villages[i];
        this.add.sprite(spriteVillage.coords.x*32*3-42, spriteVillage.coords.y*32*3-42, 'village').setInteractive(); 
        var villageColor = 0x808080; //gray
        if (spriteVillage.coords.x == mapInfoList.villageList.selectedVillage.x && spriteVillage.coords.y == mapInfoList.villageList.selectedVillage.y) villageColor = 0xFFFFFF;
        else villageColor = 0x7F3300;
        this.add.rectangle(spriteVillage.coords.x*32*3-58, spriteVillage.coords.y*32*3-27, 32, 3, villageColor).setOrigin(0, 0)
      }
      if (mapInfoList.villageList?.selectedVillage != undefined)
      {
        this.cameras.main.centerOn(mapInfoList.villageList?.selectedVillage.x*3*32, mapInfoList.villageList?.selectedVillage.y*3*32); 
      }
      this.villagesList = mapInfoList.villageList;

      if (mapInfoList?.tutorialDetail?.inProgress == false && mapInfoList?.tutorialDetail?.type == "MapZoom" && mapInfoList?.tutorialDetail?.name == "SendWorkers")
      {
        if (mapInfoList?.tutorialDetail?.name == "SendWorkers")
         {
          this.highlightSendWorkers = true;
         }
         this.graphics = this.add.graphics({
            x: this.villagesList?.selectedVillage.x*32*3 - 90,
            y: this.villagesList?.selectedVillage.y*32*3 - 58
         })
         .fillStyle(0xffff00, 0.75)
         .fillRect(0, 0, 32, 32)
   
         this.tweens.add({targets: this.graphics, alpha: 0, ease: 'Cubic.easeOut',  duration: 700, repeat: -1, yoyo: true })
      }

      for (var i = 0; i < mapInfoList.fieldList.fields.length; i++)
      {
        var spriteField = mapInfoList.fieldList.fields[i];
        if (spriteField.fieldTroops.length > 0)
        {
            if (spriteField.fieldTroops[0].ownerStatus == "Own")
                this.add.sprite(spriteField.detailCoords.x*32*3-16, spriteField.detailCoords.y*32*3-16, 'knightYellow').setInteractive(); 
            if (spriteField.fieldTroops[0].ownerStatus == "Ally")
                this.add.sprite(spriteField.detailCoords.x*32*3-16, spriteField.detailCoords.y*32*3-16, 'knightBlue').setInteractive(); 
            if (spriteField.fieldTroops[0].ownerStatus == "Unknown")
                this.add.sprite(spriteField.detailCoords.x*32*3-16, spriteField.detailCoords.y*32*3-16, 'knightGrey').setInteractive(); 
            if (spriteField.fieldTroops[0].ownerStatus == "Enemy")
                this.add.sprite(spriteField.detailCoords.x*32*3-16, spriteField.detailCoords.y*32*3-16, 'knightRed').setInteractive(); 
        }
        if (spriteField.fieldImprovements != undefined)
        {
          if (spriteField.fieldImprovements.cornFarm.isCompleted == true)
              this.add.sprite(spriteField.detailCoords.x*32*3-16, spriteField.detailCoords.y*32*3-16, 'cornFarm').setInteractive(); 
          if (spriteField.fieldImprovements.cowsFarm.isCompleted == true)
              this.add.sprite(spriteField.detailCoords.x*32*3-16, spriteField.detailCoords.y*32*3-16, 'cowsFarm').setInteractive(); 
        }
      }
    }

    update() {

    }  

    clickMiniMapToDetail(p)
    {
      //console.log("clickMiniMapToDetail:" + (((p.worldX)*3*32)/6) + "," + (p.worldY*3*32));
      console.log("CentorOnDetail:" + xShared*3*32 + "," + xShared*3*32);
      //this.cameras.main.centerOn(Math.trunc((((p.worldX)*3*32)/6)), Math.trunc((((p.worldY)*3*32)/6)));
      this.cameras?.main?.centerOn(Math.trunc(xShared*3*32), Math.trunc(yShared*3*32));
    }

    changeToMainMap(f)
    {
      // phaserGameDetail.destroy(true);
      // phaserGame = new Phaser.Game(mapConfig);
      this.time.delayedCall(100, this.scene.switch, ['main'], this.scene);
      //this.scene.start("main");
      eventsCenter.emit('center-main-map', null);
        // this.time.delayedCall(200, () => {
        //   this.cameras.main.centerOn(Math.trunc(xShared * 32 * 3), Math.trunc(yShared * 32 * 3));
        // }, null, this.scene); 
    }
    
    setTooltip(): Phaser.GameObjects.Container
    {

      const tooltip = { text: ['Spontan231 (50|50)',
      'Points: 300',
      'Owner: Spontan231']}
        
      const tooltipX = 0 //village.getRightCenter().x
      const tooltipY = 0  // village.y - 12
      const textPadding = 10
      
      this.tooltipText = this.add.text(textPadding, textPadding, tooltip.text, 
        { font: "18px monospace", color: '#000' });
      const background = this.add.rectangle(0, 0, this.tooltipText.displayWidth + (textPadding * 2), this.tooltipText.displayHeight + (textPadding * 2), 0xFFD855).setOrigin(0, 0)
      
      // Put both text and background in a cointainer to easily position them
      const tooltipContainer = this.add.container(tooltipX, tooltipY)
      tooltipContainer.add(background)
      tooltipContainer.add(this.tooltipText)
      return tooltipContainer;
    }
  }




