import { Component, Injectable } from '@angular/core';
import { MenuIconStatus } from '@app/_models/info/menuIconStatus';
import { MainbarDetail } from '../_models/village/mainbarDetail';
import { SourcesAreMax } from '../_models/village/sourcesAreMax';
import { SourcesDemand } from '@app/_models/village/sourcesDemand';
import { HighlightDetail } from '@app/_models/village/highlightDetail';
import { environment } from '@environments/environment';
import { TutorialDetail } from '@app/_models/profile/tutorialDetail';
import { ProfileDetail } from '@app/_models';


@Injectable({ providedIn: 'root' })
export class SourcesHelper {
    mainbarDetail : MainbarDetail;
    actualDateUtc: Date;
    tutorialDetail: TutorialDetail;
    actualDate: Date;

    constructor(
        
    ) {}

    async checkMainbarDetailSources(currentUrl: string)
    {
      if (this.mainbarDetail?.villageSources != undefined)
      {
        //this.mainbarDetail = this.getActualMainbarSourcesDemand(this.mainbarDetail);
        //this.mainbarDetail = this.getActualSpace(this.mainbarDetail);
        var currentDate = new Date();
        //var currentDateUtc = new Date(currentDate.getUTCFullYear(),currentDate.getUTCMonth(),currentDate.getUTCDate(),currentDate.getUTCHours(),currentDate.getUTCMinutes(),currentDate.getUTCSeconds());
        if (this.mainbarDetail.frontendDate == undefined) 
        {
          this.mainbarDetail.frontendDate = this.mainbarDetail.serverDate;
          this.actualDate = new Date();
          //this.actualDateUtc = new Date(actualDate.getUTCFullYear(),actualDate.getUTCMonth(),actualDate.getUTCDate(),actualDate.getUTCHours(),actualDate.getUTCMinutes(),actualDate.getUTCSeconds());
        }
        // var duration = currentDateUtc.getTime() - this.actualDateUtc.getTime();
        // const result = new Date(this.mainbarDetail.serverDate);
        // result.setMinutes(result.getMinutes() + result.getTimezoneOffset() + environment.timeZoneMinutes)
        // var newDate = result.getTime() + duration;
        // this.mainbarDetail.frontendDate = new Date(newDate);
        var duration = currentDate.getTime() - this.actualDate.getTime();
        const result: Date  = new Date(this.mainbarDetail.serverDate);
        result.setHours(result.getHours() -1);
        //result.setMinutes(result.getMinutes() + result.getTimezoneOffset() + environment.timeZoneMinutes)
        var newDate = result.getTime() + duration;
        this.mainbarDetail.frontendDate = new Date(newDate);
        this.mainbarDetail = this.getActualMainbarSourcesDemand(this.mainbarDetail);
        this.mainbarDetail = this.getActualSpace(this.mainbarDetail);
        this.mainbarDetail.currentUrl = currentUrl;
        if (this.mainbarDetail.highlightDetail == undefined) this.mainbarDetail.highlightDetail = new HighlightDetail();
        if (this.mainbarDetail.tutorialDetail != undefined && this.mainbarDetail.tutorialDetail.inProgress == false)
        {
          if (this.mainbarDetail.tutorialDetail.type == "Building") 
          {
            if (currentUrl.includes("village/details") || currentUrl.includes("building/townhall"))
            {
                this.mainbarDetail.highlightDetail.villageName = false;
            }
            else
            {
              this.mainbarDetail.highlightDetail.villageName = true;
            }
          }
          if (this.mainbarDetail.tutorialDetail.type == "Recruitment" || this.mainbarDetail.tutorialDetail.type == "Technology"  || this.mainbarDetail.tutorialDetail.type == "Marketplace" || this.mainbarDetail.tutorialDetail.type == "Castle")
          {
            if (currentUrl.includes("village/details"))
            {
               this.mainbarDetail.highlightDetail.villageName = false;
            }
            else if (currentUrl.includes("building/marketplace"))
            {
              this.mainbarDetail.highlightDetail.villageName = false;
            }
            else if (currentUrl.includes("building/newoffer"))
            {
              this.mainbarDetail.highlightDetail.villageName = false;
            }
            else if (currentUrl.includes("building/castle"))
            {
              this.mainbarDetail.highlightDetail.villageName = false;
            } 
            else if (currentUrl.includes("building/workshop"))
              {
                this.mainbarDetail.highlightDetail.villageName = false;
              }
            else
            {
              if (currentUrl.includes("building/houses") && (this.mainbarDetail.tutorialDetail.name == "Robotników" || this.mainbarDetail.tutorialDetail.name == "Explorer"))
              {
                this.mainbarDetail.highlightDetail.villageName = false;
              }
              else
              {
                this.mainbarDetail.highlightDetail.villageName = true;
              }
            }
          }
        }
        else
        {
          this.mainbarDetail.highlightDetail.villageName = false;
        }
       }
       return this.mainbarDetail;
    }

    async checkTutorialDetail(profileDetail: ProfileDetail)
    {
        if (this.tutorialDetail == undefined)
        {
          this.tutorialDetail = profileDetail?.tutorialDetail;
        }
        if (this.tutorialDetail != undefined && this.tutorialDetail.name != undefined)
        {
          if (this.tutorialDetail.type == "Building")
              this.tutorialDetail.name = this.convertBuildingTypeName(this.tutorialDetail.name);
          if (this.tutorialDetail.type == "Recruitment")
              if (this.tutorialDetail.name == "Workers")  this.tutorialDetail.name = "Robotników";
          if (this.tutorialDetail.type == "MapZoom")
              if (this.tutorialDetail.name == "SendWorkers")  this.tutorialDetail.name = "Wyślij Robotników po surowce";
          if (this.tutorialDetail.type == "Technology")
              if (this.tutorialDetail.name == "Explorer")  this.tutorialDetail.name = "Odkrywca - poziomów";
          if (this.tutorialDetail.type == "Marketplace")
              if (this.tutorialDetail.name == "MakeOffer")  this.tutorialDetail.name = "Dodaj ofertę na rynku";
          if (this.tutorialDetail.type == "Castle")
          {
              if (this.tutorialDetail.name == "CreateCaptain")  this.tutorialDetail.name = "Powołaj Kapitana";
              if (this.tutorialDetail.name == "TrainCaptain")  this.tutorialDetail.name = "Trenuj Kapitana";
          }
          if (this.tutorialDetail.type == "Info")
            if (this.tutorialDetail.name == "ReadReport")  this.tutorialDetail.name = "Odczytaj raport";

        }
        if (this.tutorialDetail != undefined && this.tutorialDetail.type != undefined)
        {
            if (this.tutorialDetail.type == "Building")  this.tutorialDetail.typeName = "Budynek";
            if (this.tutorialDetail.type == "Recruitment")  this.tutorialDetail.typeName = "";
            if (this.tutorialDetail.type == "MapZoom") this.tutorialDetail.typeName = "";
            if (this.tutorialDetail.type == "Technology") this.tutorialDetail.typeName = "Zbadaj Technologię";
            if (this.tutorialDetail.type == "Marketplace") this.tutorialDetail.typeName = "";
            if (this.tutorialDetail.type == "Castle") this.tutorialDetail.typeName = "";
            if (this.tutorialDetail.type == "Info") this.tutorialDetail.typeName = "";
        }
        return this.tutorialDetail;
    }

    async updateMainbarDetailSources(mainbarDetail: MainbarDetail)
    {
      this.mainbarDetail = mainbarDetail;
      this.tutorialDetail = mainbarDetail?.tutorialDetail;
    }

    async updateTutorialDetail(tutorialDetail: TutorialDetail)
    {
      this.tutorialDetail = tutorialDetail;
    }

    async checkMenuIconStatus(currentUrl: string)
    {
      var menuIconStatus = new MenuIconStatus();
      menuIconStatus.currentUrl = currentUrl;
      if (this.mainbarDetail != undefined)
      {
          menuIconStatus.isNewMessage = this.mainbarDetail.IsNewMessage;
      }
      return menuIconStatus;
    }

    getActualMainbarSourcesDemand(mainbarDetail: MainbarDetail)
    {
        var timeSpan = this.getTimeSpan(mainbarDetail.villageSources.lastUpdateDate);
        mainbarDetail = this.getActuallSource(mainbarDetail, timeSpan);
        return mainbarDetail;
    }

    getActuallSource(mainbarDetail: MainbarDetail, timeSpan: number)
    {
      mainbarDetail.sourcesAreMax = new SourcesAreMax();
      if (mainbarDetail.villageSources.sourcesDisplayed == undefined) mainbarDetail.villageSources.sourcesDisplayed = new SourcesDemand();
      var woodSources = mainbarDetail.villageSources.sources.wood + Math.floor(timeSpan * mainbarDetail.buildingProduction.forest.production/3600);
      if (woodSources > mainbarDetail.maxSources)
      {
        mainbarDetail.villageSources.sourcesDisplayed.wood = mainbarDetail.maxSources;
        mainbarDetail.sourcesAreMax.wood = true;
      }
      else
      {
        mainbarDetail.villageSources.sourcesDisplayed.wood = woodSources;
        mainbarDetail.sourcesAreMax.wood = false;
      }

      var bricksSources = mainbarDetail.villageSources.sources.bricks + Math.floor(timeSpan * mainbarDetail.buildingProduction.brickYard.production/3600);
      if (bricksSources > mainbarDetail.maxSources)
      {
        mainbarDetail.villageSources.sourcesDisplayed.bricks = mainbarDetail.maxSources;
        mainbarDetail.sourcesAreMax.bricks = true;
      }
      else
      {
        mainbarDetail.villageSources.sourcesDisplayed.bricks = bricksSources;
        mainbarDetail.sourcesAreMax.bricks = false;
      }

      var stoneSources = mainbarDetail.villageSources.sources.stone + Math.floor(timeSpan * mainbarDetail.buildingProduction.quarry.production/3600);
      if (stoneSources > mainbarDetail.maxSources)
      {
        mainbarDetail.villageSources.sourcesDisplayed.stone = mainbarDetail.maxSources;
        mainbarDetail.sourcesAreMax.stone = true;
      }
      else
      {
        mainbarDetail.villageSources.sourcesDisplayed.stone = stoneSources;
        mainbarDetail.sourcesAreMax.stone = false;
      }

      var grainSources = mainbarDetail.villageSources.sources.grain + Math.floor(timeSpan * mainbarDetail.buildingProduction.field.production/3600);
      if (grainSources > mainbarDetail.maxSources)
      {
        mainbarDetail.villageSources.sourcesDisplayed.grain = mainbarDetail.maxSources;
        mainbarDetail.sourcesAreMax.grain = true;
      }
      else
      {
        mainbarDetail.villageSources.sourcesDisplayed.grain = grainSources;
        mainbarDetail.sourcesAreMax.grain = false;
      }
       return mainbarDetail;
    }

    getActualSpace(mainbarDetail: MainbarDetail)
    {
       var soldiersHousesSpace = mainbarDetail.soldiersHousesSpace.space;
       if (soldiersHousesSpace > mainbarDetail.soldiersHousesSpace.maxSpace)
       {
        mainbarDetail.soldiersHousesSpace.space = mainbarDetail.soldiersHousesSpace.maxSpace
        mainbarDetail.soldiersHousesSpace.spaceIsMax = true;
       }
       else mainbarDetail.soldiersHousesSpace.spaceIsMax = false;

       var housesSpace = mainbarDetail.housesSpace.space;
       if (housesSpace > mainbarDetail.housesSpace.maxSpace)
       {
        mainbarDetail.housesSpace.space = mainbarDetail.housesSpace.maxSpace
        mainbarDetail.housesSpace.spaceIsMax = true;
       }
       else mainbarDetail.housesSpace.spaceIsMax = false;
       return mainbarDetail;
    }

    getTimeSpan(lastUpdateDate: Date)
    {
      if (this.mainbarDetail?.frontendDate != undefined)
      {
        //var currentDate = new Date();
        //var currentDateUtc = new Date(currentDate.getUTCFullYear(),currentDate.getUTCMonth(),currentDate.getUTCDate(),currentDate.getUTCHours(),currentDate.getUTCMinutes(),currentDate.getUTCSeconds());
        // if (this.mainbarDetail.frontendDate == undefined) this.mainbarDetail.frontendDate = this.mainbarDetail.serverDate;
        // const result = new Date(this.mainbarDetail.frontendDate);
        // result.setSeconds(1 + result.getSeconds());
        //var dateTimeNow: number =  this.mainbarDetail?.frontendDate.valueOf()/1000;
        //const formattedDate = new Date(Date.parse(lastUpdateDate.toString()));
        const frontendDate: Date = new Date(this.mainbarDetail?.frontendDate);
        //frontendDate.setHours(frontendDate.getHours() -1);
        var dateTimeNow: number =  frontendDate.valueOf()/1000;
        const formattedDate: Date = new Date(lastUpdateDate);
        formattedDate.setHours(formattedDate.getHours() -1);
        var lastUpdate: number = formattedDate.valueOf()/1000;
        var timeSpan = dateTimeNow - lastUpdate;
        return timeSpan;
      }
    }

    convertBuildingTypeName(buildingType: string)
    {
       if (buildingType == 'TownHall')
          return 'Ratusz';
        if (buildingType == 'Castle')
          return 'Zamek';
        if (buildingType == 'WallSector1')
          return 'Mur sektor 1';
        if (buildingType == 'WallSector2')
          return 'Mur sektor 2';
        if (buildingType == 'Place')
          return 'Plac';
        if (buildingType == 'Marketplace')
          return 'Rynek';  
        if (buildingType == 'Forest')
          return 'Tartak';  
        if (buildingType == 'BrickYard')
          return 'Cegielnia';  
        if (buildingType == 'Quarry')
          return 'Kamieniołom';  
        if (buildingType == 'Field')
          return 'Pole';  
        if (buildingType == 'Storage')
          return 'Magazyn';  
        if (buildingType == 'Granary')
          return 'Spichlerz';  
        if (buildingType == 'HeavyStorage')
          return 'Duży magazyn';  
        if (buildingType == 'Barracks')
          return 'Koszary'; 
        if (buildingType == 'ArcheryTower')
          return 'Wieża łucznicza'; 
        if (buildingType == 'Stable')
          return 'Stajnia'; 
        if (buildingType == 'CatapultTower')
          return 'Wieża Katapult'; 
        if (buildingType == 'Houses')
          return 'Domy'; 
        if (buildingType == 'SoldiersHouse')
          return 'Baraki'; 
        if (buildingType == 'Workshop')
          return 'Warsztat'; 
        if (buildingType == 'Alchemist')
          return 'Alchemik'; 

        return buildingType;
    }
}