import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConvertTimePipe } from '@app/_pipes/convert-time.pipe';
import { SourceValuePipe } from '@app/_pipes/source-value.pipe';

@NgModule({
  declarations: [
    ConvertTimePipe,
    SourceValuePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ConvertTimePipe,
    SourceValuePipe
  ]
})
export class SharedModule { }