import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'source'})
export class SourceValuePipe implements PipeTransform {
  transform(value: number): string {
    if (value != null) 
    {   
      if (value >= 10000)
      {
          const valueString = value.toString();
          const editedText = valueString.slice(0, -3)
          return editedText + "K";
      }      
      return value.toString();
    }
  }
}